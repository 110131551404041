import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import "./Teachers.css";

const Teachers = () => {
  const [teachers, setTeachers] = useState({ teacherA: null, teacherB: null });
  const [selectedTeacherId, setSelectedTeacherId] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [forceUpdate, setForceUpdate] = useState(0); // New state to force update

  useEffect(() => {
    const id = searchParams.get("id");
    fetchTeachers(id);
  }, [searchParams, forceUpdate]); // Include forceUpdate in dependencies

  const fetchTeachers = (id = "") => {
    const url = id
      ? `https://dolphin-app-9mena.ondigitalocean.app/teachers?id=${id}`
      : "https://dolphin-app-9mena.ondigitalocean.app/teachers";
    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Error fetching data: ${response.statusText}`);
        }
        return response.json();
      })
      .then((data) => {
        setTeachers(data);
        setSelectedTeacherId(null); // Reset selected teacher
      })
      .catch((error) => console.error("Error fetching data:", error));
  };

  const handleSelectionChange = (event) => {
    setSelectedTeacherId(event.target.value);
  };

  const handleSubmit = () => {
    if (selectedTeacherId) {
      setSearchParams({ id: selectedTeacherId });
      setForceUpdate(forceUpdate + 1); // Force update the component
    }
  };

  return (
    <div className="teachers-container">
      {Object.values(teachers).map(
        (teacher, index) =>
          teacher && (
            <div key={teacher._id} className="teacher-card">
              <img
                src={teacher.image}
                alt={teacher.fullName}
                className="teacher-image"
              />
              <h2>{teacher.fullName}</h2>
              <p>{teacher.major}</p>
              <label>
                <input
                  type="radio"
                  name="teacher"
                  value={teacher._id}
                  checked={teacher._id === selectedTeacherId}
                  onChange={handleSelectionChange}
                />
                Select
              </label>
            </div>
          )
      )}
      <button onClick={handleSubmit} disabled={!selectedTeacherId}>
        Submit
      </button>

      {selectedTeacherId && teachers[selectedTeacherId] && (
        <div className="selected-teacher-details">
          <h3>Selected Teacher Details</h3>
          <p>ID: {teachers[selectedTeacherId]._id}</p>
          <p>Name: {teachers[selectedTeacherId].fullName}</p>
          <p>Major: {teachers[selectedTeacherId].major}</p>
          <img
            src={teachers[selectedTeacherId].image}
            alt={teachers[selectedTeacherId].fullName}
            className="teacher-image"
          />
        </div>
      )}
    </div>
  );
};

export default Teachers;
